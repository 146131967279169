const appId = document.querySelector(".app-id");

function showAppid(show, components = null) {
  document.querySelector(".app-id").classList.remove("hidden").prop('required',false);
}

function hideAppId(){
  document.querySelector(".app-id").classList.add("hidden").prop('required',true);
}

document.addEventListener(
  "click",
  function validateFields(event) {
    const { target } = event;
    if(target.id.includes('graduate_loan_application_total_earnings_from_employment')){
      if(target.value == 'true'){
        document.querySelector(".employment_options1").classList.remove("hidden");
        document.querySelector(".employment_options2").classList.remove("hidden");
        document.querySelector(".employment_options3").classList.remove("hidden");
      }else if(target.value == 'false'){
        document.querySelector(".employment_options1").classList.add("hidden");
        document.querySelector(".employment_options2").classList.add("hidden");
        document.querySelector(".employment_options3").classList.add("hidden");

      }
    }

    if(target.id.includes('graduate_loan_application_consent_to_phone')){
      if(target.value == 'true'){
        document.querySelector(".phone_number").classList.remove("hidden");
      }else if(target.value == 'false'){
        document.querySelector(".phone_number").classList.add("hidden");
      }
    }

    if(target.matches('#graduate_loan_candidate_not_applicable')){
      if(document.getElementById('graduate_loan_candidate_scholarship_recipient.value') == 'the_dream_dot_us'){
        if(target.value == '1'){
          $('#app_id').removeAttr('required');
        }else{
          $('#app_id').prop('required',true); 
        }
      }else{
        $('#app_id').removeAttr('required');
      }
    }
  },
  false
);

document.addEventListener(
  "change",
  function addAppID(event) {
    const { target } = event;
    if (target.matches('#graduate_loan_candidate_scholarship_recipient')) {
      if (target.value=== 'the_dream_dot_us') {
        showAppid();
      }else{
        hideAppId();
      }
    }
  },
  false
);
