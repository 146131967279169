$(document).on(
  "turbolinks:load",
  function prequalificationTwoTurblolinksLoad() {
    $(".fu-date-picker").fuDatePicker();
    // $('.center-select').centerSelect();
    $(".checkbox-of-hiding").checkboxOfHiding();
    $(".clipboard").clipboard();
    $(".link-of-hiding").linkOfHiding();
    $("input.validate-on-blur").validateOnBlur();
    $("input.format-hours-completed").hoursCompletedValidation();
    $("input[type=radio].require-checkbox").requireCheckbox();
    $("input.format-phone").formatPhone();
    $("input.format-ssn").formatSsn();
    $("input.format-confirm-ssn").formatConfirmSsn();
    $("input.format-loan-amount").splitDisbursements();
    $("input.calculate-financial-need").calculateFinancialNeed();
    $("input.calculate-apprenta-financial-need").calculateFinancialNeedApprenta();
    $("input.repayment-changed").updateRepayment();
    $(".step-106").loadPrequalificationTwoIn5s();
    $("#prequalifiction-two-step-7").step7Submit();
    $("#prequalification_two_grad_year").eligibleGradMonth();
    $(".grad-date").checkFreshman();
    $(".residence-state").checkMpower();
    $(".class-year").checkClassYear();
    $(".grad-date").checkClassYear();
    $(".clear-graduate-school").clearSchool();
    $(".clear-graduate-program").clearProgram();
    $(".class-format").checkClassFormat();

    // TODO: refactor to use microplugin pattern.
    $("#v3-out-of-season-form").on(
      "submit",
      function v3OutOfSeasonFormSubmit() {
        $("#v3-out-of-season-form-id").remove();
        $("#v3-out-of-season-title-id").text("You are on the list.");
        $("#v3-out-of-season-text-id").text(
          "We will reach out when the application period for next semester opens.  Thank you!"
        );
        $("#v3-out-of-season-subtitle-id > br").each(function removeSubitle() {
          this.remove();
        });
      }
    );

    $("#step_3_submit").click(function clickStep3Submit(e) {
      const monthlyEarnings = $(".calculate-repayment-monthly-earnings").val();
      const monthlyAllowance = $(
        ".calculate-repayment-monthly-allowance"
      ).val();
      const annualSavings = $(".calculate-repayment-annual-savings").val();
      debugger;
      const totalRepaymentCapability =
        monthlyEarnings * 12 + monthlyAllowance * 12 + annualSavings * 1;

      if (totalRepaymentCapability < 240) {
        $(".step-3-error-message").show();
        $("#step_3_submit").attr("disabled", true);
        e.preventDefault();
      }
    });

    $("#step_3_back").click(function clickStep3back() {});
  }
);

// TODO: this might be stored in a top level structure instead of loose.

const messageCurrency = function setMessageCurrency(value) {
  return `$${value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
};

$.fn.calculateFinancialNeedApprenta = function calculateApprentaFinancialNeed() {

  $(this).keyup(function calculateApprentaFinancialNeedKeyUp() {
    const estimatedFinancialAid = $(".estimated-financial-aid");
    const costOfAttendance = $(".cost-of-attendance");
    const need = costOfAttendance.val() - estimatedFinancialAid.val()
    const financialNeed = $(".financial-need");
    const hiddenFinancialNeed = $(".hidden-financial-need");
    financialNeed.val(need);
    hiddenFinancialNeed.val(need)
  });

}

$.fn.calculateFinancialNeed = function updateCalculateFinancialNeed() {
  if(document.getElementById('questions') != null){
    document.getElementById('questions').style.display = 'none';
    document.getElementById('questions-label').style.display = 'none';
  }
  $(this).keyup(function calculateFinancialNeedKeyUp() {
    const costOfAttendance = $(".calculate-financial-need.cost-of-attendance");
    const estimatedFinancialAid = $(
      ".calculate-financial-need.estimated-financial-aid"
    );
    const financialNeed = $(".financial-need");
    const need = costOfAttendance.val() - estimatedFinancialAid.val()
    const loanAmount = $(".loan-amount").val();
    financialNeed.val(messageCurrency(need));
    const enoughamount = need - parseInt(loanAmount)
    if(document.getElementById('questions') != null){
      if(enoughamount > 10000){
        document.getElementById('questions-label').style.display = 'block';
        document.getElementById('questions').style.display = 'block';
      }else{
        document.getElementById('questions').style.display = 'none';
        document.getElementById('questions-label').style.display = 'none';
      }
    }

  });
};

$.fn.centerSelect = function formatCenterSelect() {
  const update = function formatCenterSelectUpdate() {
    const text = $(this).find("option:selected").text();
    const padding = ($(this).width() - $(this).textWidth(text)) / 2;
    $(this).css("padding-left", `${padding}px`);
    return this;
  };
  $(this).change(update);
  $(this).trigger("change");
};

$.fn.checkboxOfHiding = function checkboxOfHidingFunc() {
  const update = function checkboxOfHidingUpdate() {
    const hides = $($(this).attr("hides"));
    const shows = $($(this).attr("shows"));
    const preventInitialShow = $($(this).attr("prevent-initial-show"));
    const noAnimation = $(this).attr("no-animation");
    const requiredOnShow = $(this).attr("required-on-show");
    let duration = 0.3;
    if (noAnimation) {
      duration = 0;
    }

    if (this.checked) {
      hides.fadeOut(duration);
      shows.fadeIn(duration);
      if (requiredOnShow) {
        hides.find("input, select").removeAttr("required");
        shows
          .find(
            "input[required-on-show!=false], select[required-on-show!=false]"
          )
          .attr("required", true);
      }
    } else {
      hides.fadeIn(duration);
      shows.fadeOut(duration);
      if (requiredOnShow) {
        hides.find("input, select").attr("required", true);
        shows.find("input, select").removeAttr("required");
      }
    }
    if (!preventInitialShow) {
      $(this).trigger("change");
    }
    debugger;
    if(this.id == 'prequalification_two_has_monthly_earnings_false'){
      document.getElementById('prequalification_two_monthly_earnings').value = 0
    }
    
    if(this.id == 'prequalification_two_has_allowance_false'){
      document.getElementById('prequalification_two_allowance').value = 0 
    }
    
    if(this.id == 'prequalification_two_has_savings_false'){
      document.getElementById('prequalification_two_savings').value = 0
    }
    $(".step-3-error-message").hide();
    $("#step_3_submit").removeAttr("disabled");
  };
  $(this).change(update);
};

$.fn.clipboard = function clipboardFunction() {
  $(this).click(function copyToClipboard() {
    const el = document.createElement("textarea");
    el.value = $("#clipboard-text").text();
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  });
};

$.fn.validateOnBlur = function validateOnBlurFunc() {
  $(this).blur(function validateOnBlurBlur() {
    $(this)[0].reportValidity();
  });
};

$.fn.hoursCompletedValidation = function hoursCompletedValidationFunc(){
  $(this).keyup(function hoursCompletedValidation() {
    const input_number = parseFloat(this.value)
    if(Number.isInteger(input_number)){
      $(this)[0].setCustomValidity('')
    }else{
      $(this)[0].setCustomValidity('Please enter a whole number')
      return false;
    }

    if(input_number < 0){
      $(this)[0].setCustomValidity('Please enter a positive number')
    }else if(input_number >= 30){
      $(this)[0].setCustomValidity('Please enter a number less than 30')
    }else{
      $(this)[0].setCustomValidity('')
    }
  });
}

$.fn.linkOfHiding = function linkOfHidingFunc() {
  $(this).click(function linkOfHidingUpdate(e) {
    e.preventDefault();
    const hides = $($(this).attr("hides"));
    const shows = $($(this).attr("shows"));
    const noAnimation = $(this).attr("no-animation");
    const requiredOnShow = $(this).attr("required-on-show");
    let duration = 0.3;
    if (noAnimation) {
      duration = 0;
    }

    $(this).fadeOut(duration);
    hides.fadeOut(duration);
    shows.fadeIn(duration);
    if (requiredOnShow) {
      hides.find("input, select").removeAttr("required");
      shows
        .find("input[required-on-show!=false], select[required-on-show!=false]")
        .attr("required", true);
    }
  });
};

$.fn.formatPhone = function formatPhoneFunc() {
  $(this).keydown(function formatPhoneKeydown(e) {
    const keycodes = [8, 9, 27, 13, 46];
    if (
      $.inArray(e.keyCode, keycodes) !== -1 ||
      (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
      (e.keyCode >= 35 && e.keyCode <= 40)
    ) {
      return;
    }
    if (
      $(this).val().length >= 12 ||
      ((e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
        (e.keyCode < 96 || e.keyCode > 105))
    ) {
      e.preventDefault();
    }
  });
  $(this).keyup(function formatPhoneKeyup() {
    let newValue = $(this).val().replace(/\D/g, "");
    newValue = newValue.substring(0, 10);
    if (newValue.length > 6) {
      newValue = newValue.replace(/(\d{3})(\d{3})(\d{0,4})/, "$1-$2-$3");
    } else if (newValue.length > 3) {
      newValue = newValue.replace(/(\d{3})(\d{0,3})/, "$1-$2");
    }
    $(this).val(newValue);
  });
};

$.fn.formatSsn = function formatSsnFunc() {
  $(this).keydown(function formatSsnKeydown(e) {
    const keycodes = [8, 9, 27, 13, 46];
    if (
      $.inArray(e.keyCode, keycodes) !== -1 ||
      (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
      (e.keyCode >= 35 && e.keyCode <= 40)
    ) {
      return;
    }
    if (
      $(this).val().length >= 11 ||
      ((e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
        (e.keyCode < 96 || e.keyCode > 105))
    ) {
      e.preventDefault();
    }
  });
  $(this).keyup(function formatSsnKeyup() {
    const hiddenSsn = $("#hidden-ssn");
    let hiddenValue = hiddenSsn.val();
    hiddenValue = hiddenValue.replace(/\D/g, "");
    let newValue = $(this).val();
    newValue = newValue.replace(/-/g, "");

    if (hiddenValue.length > newValue.length) {
      hiddenValue = hiddenValue.substring(0, newValue.length);
    } else {
      hiddenValue += newValue.replace(/\D/g, "");
    }
    hiddenValue = hiddenValue.substring(0, 9);
    if (hiddenValue.length > 5) {
      hiddenValue = hiddenValue.replace(/(\d{3})(\d{2})(\d{0,4})/, "$1-$2-$3");
    } else if (hiddenValue.length > 3) {
      hiddenValue = hiddenValue.replace(/(\d{3})(\d{0,2})/, "$1-$2");
    }
    newValue = hiddenValue.replace(/\d/g, "X");
    $(hiddenSsn).val(hiddenValue);
    $(this).val(newValue);
    const confirm_ssn = $('#confirm-hidden-ssn').val()
    const ssn = $('#hidden-ssn').val()
    if(confirm_ssn == ssn){
      document.getElementById('ssn-error').style.display = 'none'
      document.getElementById('eligibility').disabled = false

    }else{
      document.getElementById('ssn-error').style.display = 'block'
      document.getElementById('eligibility').disabled = true
    }
  });
};

$.fn.formatConfirmSsn = function formatConfirmSsnFunc() {
  $(this).keydown(function formatConfirmSsnKeydown(e) {
    const keycodes = [8, 9, 27, 13, 46];
    if (
      $.inArray(e.keyCode, keycodes) !== -1 ||
      (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
      (e.keyCode >= 35 && e.keyCode <= 40)
    ) {
      return;
    }
    if (
      $(this).val().length >= 11 ||
      ((e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
        (e.keyCode < 96 || e.keyCode > 105))
    ) {
      e.preventDefault();
    }
  });
  $(this).keyup(function formatConfirmSsnKeyup() {
    const hiddenSsn = $("#confirm-hidden-ssn");
    let hiddenValue = hiddenSsn.val();
    hiddenValue = hiddenValue.replace(/\D/g, "");
    let newValue = $(this).val();
    newValue = newValue.replace(/-/g, "");

    if (hiddenValue.length > newValue.length) {
      hiddenValue = hiddenValue.substring(0, newValue.length);
    } else {
      hiddenValue += newValue.replace(/\D/g, "");
    }
    hiddenValue = hiddenValue.substring(0, 9);
    if (hiddenValue.length > 5) {
      hiddenValue = hiddenValue.replace(/(\d{3})(\d{2})(\d{0,4})/, "$1-$2-$3");
    } else if (hiddenValue.length > 3) {
      hiddenValue = hiddenValue.replace(/(\d{3})(\d{0,2})/, "$1-$2");
    }
    newValue = hiddenValue.replace(/\d/g, "X");
    $(hiddenSsn).val(hiddenValue);
    $(this).val(newValue);
    const confirm_ssn = $('#confirm-hidden-ssn').val()
    const ssn = $('#hidden-ssn').val()
    if(confirm_ssn == ssn){
      document.getElementById('ssn-error').style.display = 'none'
      document.getElementById('eligibility').disabled = false
    }else{
      document.getElementById('ssn-error').style.display = 'block'
      document.getElementById('eligibility').disabled = true
    }
  });
};

$.fn.fuDatePicker = function fuDatePickerFunc() {
  const input = document.createElement("input");
  const value = "a";
  input.setAttribute("type", "date");
  input.setAttribute("value", value);
  const nativeDatePickerSupported = input.value !== value;

  if (!nativeDatePickerSupported) {
    if ($(this).val()) {
      const offset = new Date().getTimezoneOffset() / 60;
      const date = new Date(
        `${$(this).val()}T${offset <= 9 ? `0${offset}` : offset}:00:00.000Z`
      );
      $(this).val(
        date.toLocaleString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        })
      );
    }
    $(this).prop("required", true);
    $(this).attr({
      pattern: "\\d{1,2}/\\d{1,2}/\\d{4}",
      title: "This field is required",
    });
  }
};

$.fn.loadPrequalificationTwoIn5s = function loadPrequalificationTwoIn5sFunc() {
  if (!$(this).length) {
    return;
  }
  setTimeout(function loadPrequalificationTwoIn5sFuncTimeout() {
    window.location.href = window.location.href;
  }, 5000);
};

$.fn.requireCheckbox = function requireCheckboxFunc() {
  const name = this.attr("name");
  const requiredCheckbox = this;
  const requiredValue = this.val();
  const all = $(`input[name='${name}']`);
  const update = function requireCheckboxUpdate() {
    const selectedValue = $(`input[name='${this.name}']:checked`).val();
    if (requiredValue === selectedValue) {
      requiredCheckbox.get(0).setCustomValidity("");
    } else {
      requiredCheckbox
        .get(0)
        .setCustomValidity("Must be selected to continue.");
    }
  };
  all.change(update);
  all.trigger("change");
};

$.fn.splitDisbursements = function splitDisbursementsFunc() {
  $(this).keyup(function splitDisbursementsKeyup() {
    const fall = $("#fall-loan-amount");
    const spring = $("#spring-loan-amount");
    const header = $("#loan-amount-header");
    const caveat = $("#loan-amount-caveat");
    const minimumLoanAmount = Number(
      $(".loan-amount-variables").attr("minimum_loan_amount")
    );
    const maximumLoanAmount = Number(
      $(".loan-amount-variables").attr("maximum_loan_amount")
    );
    const disbursementQuantity = Number(
      $(".loan-amount-variables").attr("disbursement_quantity")
    );
    const semestersLeft = Number(
      $("#loan-amount-header").attr("semesters_left")
    );
    if (this.value >= minimumLoanAmount && this.value <= maximumLoanAmount) {
      let split = this.value / 1;
      if (disbursementQuantity === 2 && semestersLeft > 1) {
        split /= 2;
      }
      split = messageCurrency(split);
      header.text("");
      if (disbursementQuantity === 2 && semestersLeft > 1)
        header.text("");
      if (disbursementQuantity === 2 && semestersLeft === 1)
        header.text("");
      if (disbursementQuantity === 2)
        fall.text("");
        spring.text("");
      if (disbursementQuantity === 2) fall.show();
        spring.show();
      if (disbursementQuantity === 2 && semestersLeft === 1) spring.hide();
    } else {
      if (minimumLoanAmount === maximumLoanAmount) {
        header.text(
          `We are currently offering loans of $${minimumLoanAmount.toLocaleString()} to residents of your state.`
        );
      } else {
        header.text(
          `We are currently offering loans between $${minimumLoanAmount.toLocaleString()} and $${maximumLoanAmount.toLocaleString()} to residents of your state.`
        );
      }
      fall.hide();
      spring.hide();
      caveat.hide();
    }
  });
};

$.fn.step7Submit = function step7SubmitFunc() {
  $(this).on("click", function submitStep7() {
    const costOfAttendanceField = $(this).find(
      ".calculate-financial-need.cost-of-attendance"
    );
    if(costOfAttendanceField[0] !== undefined){
      const costOfAttendance = parseFloat(costOfAttendanceField.val());
      const estimatedFinancialAid = parseFloat(
        $(this).find(".calculate-financial-need.estimated-financial-aid").val()
      );
      const loanAmount = parseFloat(
        $(this).find("#prequalification_two_loan_amount").val()
      );
      if (costOfAttendance - estimatedFinancialAid < loanAmount) {
        costOfAttendanceField[0].setCustomValidity(
          `The financial need must be greater than or equal to the loan amount, ${messageCurrency(
            loanAmount
          )}.`
        );
      } else {
        costOfAttendanceField[0].setCustomValidity("");
      }      
    }
  });
};

$.fn.textWidth = function textWidthFunc(text) {
  const htmlCalc = $(`<span>${text}</span>`);
  htmlCalc.css("font-size", this.css("font-size")).hide().prependTo("body");
  const width = htmlCalc.width();
  htmlCalc.remove();
  return width;
};

$.fn.updateRepayment = function udpateRepaymentFunc() {
  $(this).keyup(function udpateRepaymentFuncKeyup() {
    $(".step-3-error-message").hide();
    $("#step_3_submit").removeAttr("disabled");
  });
};

$.fn.eligibleGradMonth = function eligibleGradMonthFunc() {
  $(this).on("change", function submitStep7() {
    const semestersLeft = $(this).data("semesters-left");
    const date = semestersLeft["1"].from.split("-");
    const minMonth = date[1];
    const minYear = date[0];

    for (let i = 0; i < 12; i += 1) {
      $($("#prequalification_two_grad_month option")[i]).prop(
        "disabled",
        false
      );
    }

    if ($(this).val() === minYear && minMonth > 0) {
      for (let i = 0; i < minMonth; i += 1) {
        $($("#prequalification_two_grad_month option")[i]).prop(
          "disabled",
          true
        );
      }

      $("#prequalification_two_grad_month").val("");
    }
  });
};

$.fn.checkFreshman = function checkFreshmanFunc() {
  const update = function checkFreshmanChange() {
    const month = $("#prequalification_two_grad_month").val();
    const year = $("#prequalification_two_grad_year").val();
    const semestersLeft = $("#prequalification_two_grad_year").data(
      "semesters-left"
    );
    let freshman = false;
    let senior = false;

    for (let i = 1; i <= 8; i += 1) {
      const fromD = semestersLeft[i].from.split("-");
      const toD = semestersLeft[i].to.split("-");

      if (
        year >= parseInt(fromD[0], 10) &&
        month >= parseInt(fromD[1], 10) &&
        year <= parseInt(toD[0], 10) &&
        month <= parseInt(toD[1], 10) &&
        semestersLeft[i].class_year === "freshman"
      ) {
        freshman = true;
      }

      if (
        year >= parseInt(fromD[0], 10) &&
        month >= parseInt(fromD[1], 10) &&
        year <= parseInt(toD[0], 10) &&
        month <= parseInt(toD[1], 10) &&
        semestersLeft[i].class_year === "senior"
      ) {
        senior = true;
      }
    }

    const freshmanGpaHighSchool = $(".halves.freshman_gpa_high_school");
    const gpaCumulative = $(".halves.gpa_cumulative");
    freshmanGpaHighSchool.addClass("hidden");
    freshmanGpaHighSchool.find("input, select").removeAttr("required");
    gpaCumulative.addClass("hidden");
    gpaCumulative.find("input, select").removeAttr("required");

    if (month && year) {
      const showField = freshman ? freshmanGpaHighSchool : gpaCumulative;
      showField.removeClass("hidden");
      showField.find("input, select").attr("required", true);
    }
    const seniorExceptionElement = $("#senior-status-exception-note");
    const seniorExceptionLabel = $(
      "#prequalification_two_is_certified_eligible"
    ).next("label");
    if (senior) {
      const newLabel = seniorExceptionLabel
        .text()
        .replace("full time", "full time*");
      seniorExceptionLabel.text(newLabel);
      seniorExceptionElement.removeClass("hidden");
    } else {
      const newLabel = seniorExceptionLabel
        .text()
        .replace("full time*", "full time");
      seniorExceptionLabel.text(newLabel);
      seniorExceptionElement.addClass("hidden");
    }
  };
  $(this).change(update);
  $(this).trigger("change");
};

$.fn.checkMpower = function checkMpowerFunc() {
  const update = function checkMpowerChange() {
    var modal = document.getElementById("powermodal");
    var span = document.getElementsByClassName("close")[0];
    if(document.getElementsByClassName('residence-state')[0].value == 'international_student' && document.getElementById('schoolmpower').value){
      modal.style.display = "block";
    }
    span.onclick = function() {
      modal.style.display = "none";
    }
  };
  $(this).change(update);
  $(this).trigger("change");
};

$.fn.checkClassFormat = function checkClassFormatFunc() {
  const update = function checkClassFormatChange() {
    document.getElementById('classFormatText').style.display = 'none'
    const classType = document.getElementById('class-format').value
    if (classType == 'online_only') {
      document.getElementById('classFormatText').style.display = 'block'
      document.getElementById('classFormatText').style.color = 'red'
      document.getElementById("classFormatText").style.paddingBottom = "20px";
    }else {
      document.getElementById('classFormatText').style.display = 'none'
    }
  };
  $(this).change(update);
  $(this).trigger("change");
};


$.fn.checkClassYear = function checkClassYearFunc() {
  const update = function checkClassYearChange() {
    document.getElementById('classcheck').style.display = 'none'
    const month = $("#prequalification_two_grad_month").val();
    const year = $("#prequalification_two_grad_year").val();
    const semestersLeft = $("#prequalification_two_grad_year").data(
      "semesters-left"
    );
    let class_year = '';

    for (let i = 1; i <= 8; i += 1) {
      const fromD = semestersLeft[i].from.split("-");
      const toD = semestersLeft[i].to.split("-");

      if (
        year >= parseInt(fromD[0], 10) &&
        month >= parseInt(fromD[1], 10) &&
        year <= parseInt(toD[0], 10) &&
        month <= parseInt(toD[1], 10) &&
        semestersLeft[i].class_year === "freshman"
      ) {
        class_year = 'freshman';
      }

      if (
        year >= parseInt(fromD[0], 10) &&
        month >= parseInt(fromD[1], 10) &&
        year <= parseInt(toD[0], 10) &&
        month <= parseInt(toD[1], 10) &&
        semestersLeft[i].class_year === "senior"
      ) {
        class_year = 'senior';
      }

      if (
        year >= parseInt(fromD[0], 10) &&
        month >= parseInt(fromD[1], 10) &&
        year <= parseInt(toD[0], 10) &&
        month <= parseInt(toD[1], 10) &&
        semestersLeft[i].class_year === "junior"
      ) {
        class_year = 'junior';
      }

      if (
        year >= parseInt(fromD[0], 10) &&
        month >= parseInt(fromD[1], 10) &&
        year <= parseInt(toD[0], 10) &&
        month <= parseInt(toD[1], 10) &&
        semestersLeft[i].class_year === "sophomore"
      ) {
        class_year = 'sophomore';
      }
    }
    let selected_class_year = document.getElementById('class-year').value
    let not_enough_selection = selected_class_year === '' || month === '' || year === ''
    if (class_year === selected_class_year || not_enough_selection) {
      document.getElementById('classcheck').style.display = 'none'
    }else {
      document.getElementById('classcheck').style.display = 'block'
      document.getElementById('classcheck').style.color = 'red'
      document.getElementById("classcheck").style.paddingBottom = "20px";
    }

  };
  $(this).change(update);
  $(this).trigger("change");
};

$.fn.clearSchool = function clearSchoolFunc() {
  $(this).click(function clearSchoolSelection() {
    $("#graduate_loan_application_school_name").val("");
    $("#prequalification_two_school_id").val("");
    $("#graduate_loan_application_school_name").focus();
  });
};

$.fn.clearProgram = function clearProgramFunc() {
  $(this).click(function clearProgramSelection() {
    $("#graduate_loan_application_graduate_program").val("");
    $("#graduate_loan_application_graduate_program_id").val("");
    $("#graduate_loan_application_graduate_program").focus();
  });
};

document.addEventListener(
  "click",
  function verifySecondProductButtonClicked(event) {

    const { target } = event;
    loan_application_type = $(event.target).data('loan-application-type');

    if (target.matches(".second-product-button")) {
      event.preventDefault();
      const form = document.querySelector(".edit_" + loan_application_type);
      form.submit();
    }

    if (target.className == 'additional-loans'){
      if (document.getElementById('prequalification_two_additional_loans').checked){
        document.getElementById('additional-loans-comment').style.display = 'initial'
      }else{
        document.getElementById('additional-loans-comment').style.display = 'none'
      }
    }
  },
  false
);

$(document).ready(function() {
  function displayErrorMessage(message) {
    const existingErrorMessage = $("#email-field-container").find(".error-message");
    if (existingErrorMessage.length === 0) {
      const errorMessage = $("<p>")
        .text(message)
        .addClass("error-message")
        .css("color", "red");

      $("#email-field-container").append(errorMessage);
    }
  }
  const emailInput = $('[name="user[email]"]');
  emailInput.on("input", function() {
    const enteredEmail = emailInput.val().trim().toLowerCase();
    if (enteredEmail.endsWith(".edu")) {
      displayErrorMessage(
        "Please use a personal email that does not end in .edu"
      );
    } else {
      $("#email-field-container").find(".error-message").remove();
    }
  });
});
