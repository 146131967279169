// direct_uploads.js
addEventListener("direct-upload:initialize", function (event) {
  const { target } = event;
  const { detail } = event;
  const { id } = detail;
  const { file } = detail;
  target.insertAdjacentHTML(
    "beforebegin",
    '\n    <div id="direct-upload-'
      .concat(
        id,
        '" class="direct-upload direct-upload--pending">\n      <div id="direct-upload-progress-'
      )
      .concat(
        id,
        '" class="direct-upload__progress" style="width: 0%"></div>\n      <span class="direct-upload__filename">'
      )
      .concat(file.name, "</span>\n    </div>\n  ")
  );
});
addEventListener("direct-upload:start", function (event) {
  console.log("the v3 direct upload has started");
  const { id } = event.detail;
  const element = document.getElementById("direct-upload-".concat(id));
  element.classList.remove("direct-upload--pending");
});
addEventListener("direct-upload:progress", function (event) {
  const _event$detail = event.detail;
  const { id } = _event$detail;
  const { progress } = _event$detail;
  const progressElement = document.getElementById(
    "direct-upload-progress-".concat(id)
  );
  progressElement.style.width = "".concat(progress, "%");
});
addEventListener("direct-upload:error", function (event) {
  event.preventDefault();
  const _event$detail2 = event.detail;
  const { id } = _event$detail2;
  const { error } = _event$detail2;
  const element = document.getElementById("direct-upload-".concat(id));
  element.classList.add("direct-upload--error");
  element.setAttribute("title", error);
});
addEventListener("direct-upload:end", function (event) {
  const { id } = event.detail;
  const element = document.getElementById("direct-upload-".concat(id));
  element.classList.add("direct-upload--complete");
});
