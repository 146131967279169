document.addEventListener(
  "click",
  function trainingProviderClicked(event) {
    const { target } = event;
    if (target.id == 'training_provider') {
      if(target.className == 'Hudson County Community College'){
        hideAndDisplayCybersecurity('none');
        hideAndDisplayHvac('none');
        hideAndDisplayWelding('none');

        hideAndDisplayNursing('block');
        hideAndDisplayRadiography('block');
      }

      if(target.className == 'New Jersey Institute of Technology'){
        hideAndDisplayHvac('none');
        hideAndDisplayNursing('none')
        hideAndDisplayWelding('none');
        hideAndDisplayRadiography('none');

        hideAndDisplayCybersecurity('block');
      }

      if(target.className == 'Camden County College'){
        hideAndDisplayCybersecurity('none');
        hideAndDisplayNursing('none');
        hideAndDisplayRadiography('none');

        hideAndDisplayHvac('block');
        hideAndDisplayWelding('block');
      }

      if(target.className == 'Rowan College at Burlington County'){
        hideAndDisplayCybersecurity('none');
        hideAndDisplayHvac('none');
        hideAndDisplayWelding('none');
        hideAndDisplayRadiography('none');

        hideAndDisplayNursing('block');
      }

    }
  },
  false
);


function hideAndDisplayCybersecurity(action){
   var cybersecurity = document.getElementsByClassName('cybersecurity');
    for (var i = 0; i < cybersecurity.length; i ++) {
      cybersecurity[i].style.display = action;
    }
}

function hideAndDisplayHvac(action){
   var hvac = document.getElementsByClassName('hvac');
    for (var i = 0; i < hvac.length; i ++) {
      hvac[i].style.display = action;
    }
}

function hideAndDisplayNursing(action){
   var nursing = document.getElementsByClassName('nursing');
    for (var i = 0; i < nursing.length; i ++) {
      nursing[i].style.display = action;
    }
}

function hideAndDisplayRadiography(action){
   var nursing = document.getElementsByClassName('radiography');
    for (var i = 0; i < nursing.length; i ++) {
      nursing[i].style.display = action;
    }
}

function hideAndDisplayWelding(action){
   var welding = document.getElementsByClassName('welding');
    for (var i = 0; i < welding.length; i ++) {
      welding[i].style.display = action;
    }
}

$(document).ready(function() {
  function displayErrorMessage(message) {
    const existingErrorMessage = $("#number-of-people-field-container").find(".error-message");
    if (existingErrorMessage.length === 0) {
      const errorMessage = $("<p>")
          .text(message)
          .addClass("error-message")
          .css("color", "red");

      $("#number-of-people-field-container").append(errorMessage);
    }
  }

  const numberInput = $(
      '[name="pif_loan_application[number_of_people_in_home]"]'
  );
  numberInput.on("input", function() {
    const enteredNumber = numberInput.val().trim();

    if (isNaN(enteredNumber) || enteredNumber <= 0 || enteredNumber.includes('.')) {
      displayErrorMessage("Please enter a positive integer.");
    } else {
      $("#number-of-people-field-container").find(".error-message").remove();
    }
  });
});
