/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

console.log("Hello World from Webpacker");

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("@rails/actiontext");
require("./navigation_overlay");
require("./google_analytics_events");
require("./google_tag_manager");
require("./recaptcha_form_custom");
require("./prequalification_two_form");
require("./fu_autocomplete");
require("./recaptcha_form_custom");
require("./faq");
require("./document_upload");
require("./direct_uploads");
require("./monthly_payment_selection");
require("./pif_application");
require("./graduate_loan_form");
require("../stylesheets/application.scss");
require("jquery-ui/themes/base/autocomplete.css");
require("jquery-ui/themes/base/theme.css");

window.Rails = Rails;

document.addEventListener("turbolinks:before-cache", function () {
  const nodes = document.querySelectorAll("script[nonce]");
  nodes.forEach((element) => {
    element.setAttribute("nonce", element.nonce);
  });
});
