$(document).on("turbolinks:load", function documentUploadLoad() {
  const categories = document.querySelectorAll("div.category_type");
  if (categories === undefined || categories.length === 0) {
    return;
  }
  for (let i = 0; i < categories.length; i += 1) {
    const fileField = $(categories[i]).data("file");
    const fileInput = $(`input[category=${fileField}]`);
    fileInput.change(function fileInputFunc(e) {
      const res = e.currentTarget.attributes.category.value.replace(
        "file",
        "submit"
      );
      const submitInput = $(`input[category=${res}]`);
      submitInput.attr("value", "Uploading...");
      const form = $(`#form_id_${res}`);
      form.submit();
    });
  }
});
