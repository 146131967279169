import "jquery-ui/ui/widgets/autocomplete";

$.fn.hideUnhideFinances = function hideUnhideFinancesFunc() {
  var certiMethod = document.getElementById('certification_method');
  var apprentaFinance = document.getElementById('apprenta-finances')
  if(certiMethod && apprentaFinance){
    if(certiMethod.value == 'true'){
      apprentaFinance.style.display = 'none'
    }else{
      apprentaFinance.style.display = 'block'
    }
  }
};

function graduateProgramsFunc(schoolId) {
  if ($(".graduate-program-selector").length > 0) {
    // $('#graduate_loan_application_graduate_program').val('');
    // $("#graduate_loan_application_graduate_program_id").val('');

    $(".graduate-program-selector").fuAutocomplete(
      `/graduate_programs/autocomplete?school_id=${schoolId || ""}`,
      "#graduate_loan_application_graduate_program_id",
      "select a graduate program"
    );
  }
}

function checkMpowerList() {
  if(document.getElementById("powermodal") != null){
    var modal = document.getElementById("powermodal");
    var span = document.getElementsByClassName("close")[0];
    if(document.getElementsByClassName('residence-state')[0].value == 'international_student' && document.getElementById('schoolmpower').value){
      modal.style.display = "block";
    }
    span.onclick = function() {
      modal.style.display = "none";
    }
  }

}

$(document).on("turbolinks:load", function fuAutocompleteLoad() {
  $(".apprenta-finances").hideUnhideFinances();

  $(".major-selector").fuAutocomplete(
    "/majors/autocomplete",
    "#prequalification_two_major_id",
    "select a major"
  );
  $(".school-selector").fuAutocomplete(
    "/schools/autocomplete",
    "#prequalification_two_school_id",
    "select a school"
  );
  $(".user-email-selector").fuAutocomplete(
    "/admin/users/autocomplete",
    "#user_primary_id",
    "select an email"
  );
  $(".eligible-graduate-school-selector").fuAutocomplete(
    "/eligible_graduate_schools/autocomplete",
    "#prequalification_two_school_id",
    "select a school"
  );

  $(".apprenta-school-selector").fuAutocomplete(
    "/apprenta/eligible_schools/autocomplete",
    "#apprenta_school_id",
    "select a school"
  );
  graduateProgramsFunc();
  checkMpowerList();
});

$.fn.fuAutocomplete = function fuAutocompleteFunc(
  source,
  destinationFieldId,
  message
) {
  $(this)
    .autocomplete({
      focus(event, ui) {
        event.preventDefault();
        $(this).val(ui.item.label);
        $(this).get(0).setCustomValidity("");
      },
      minLength: 2,
      source,
      search() {
        $(this).addClass("spinner");
      },
      select(event, ui) {
        $(destinationFieldId).val(ui.item.value);
        var apprentaFinance = document.getElementById('apprenta-finances');
        if(apprentaFinance){
          if(ui.item.certification_method == 'None'){
            apprentaFinance.style.display = 'none'
          }else{
            apprentaFinance.style.display = 'block'
          }
        }

        $(this).val(ui.item.label);
        $(this).get(0).setCustomValidity("");

        if(event.target.id === "prequalification_two_school_name") {
          $('#schoolmpower').val(ui.item.mpower)
          checkMpowerList();
        }

        if (event.target.id === "graduate_loan_application_school_name") {
          graduateProgramsFunc(ui.item.value);
        }

        return false;
      },
      response() {
        $(this).removeClass("spinner");
      },
    })
    .on("input", function fuAutocompleteInput() {
      $(this).get(0).setCustomValidity(message);
      $(destinationFieldId).val(null);
      return true;
    });
};
