function showNoteCalculatorElement(target) {
  const noteCalculatorElements = document.querySelectorAll(".note-calculator");
  noteCalculatorElements.forEach((noteCalculatorDiv) =>
    $(noteCalculatorDiv).hide()
  );
  const noteCalculatorElement = document.querySelector(
    `#note-calculator-${target.value}`
  );
  $(noteCalculatorElement).show();
}

function selectMonthlyRepaymentElement(target) {
  const monthlyRepaymentElements = document.querySelectorAll(
    ".checkbox-loan-group"
  );
  monthlyRepaymentElements.forEach((monthlyRepaymentDiv) =>
    monthlyRepaymentDiv.classList.remove("selected")
  );
  const monthlyRepaymentElement = document.querySelector(
    `#checkbox-loan-group-${target.value}`
  );
  monthlyRepaymentElement.classList.add("selected");
}

function showHideProductTerms(target) {
  if(target.id == '120') {
    target.classList.add("active");
    document.getElementById('60').classList.remove("active");
    document.querySelectorAll('.loan-group-120').forEach(function(el) {
      el.classList.remove('hidden');
    });
    document.querySelectorAll('.loan-group-60').forEach(function(el) {
      el.classList.add('hidden');
    });
  }else{
    target.classList.add("active");
    document.getElementById('120').classList.remove("active");
    document.querySelectorAll('.loan-group-120').forEach(function(el) {
      el.classList.add('hidden');
    });
    document.querySelectorAll('.loan-group-60').forEach(function(el) {
      el.classList.remove('hidden');
    });
  }
}

document.addEventListener(
  "click",
  function faqClicked(event) {
    const { target } = event;
    if (target.matches(".monthly-payment-selection")) {
      selectMonthlyRepaymentElement(target);
      showNoteCalculatorElement(target);
    }
    if (target.matches(".product_terms")) {
      showHideProductTerms(target);
    }
  },
  false
);
